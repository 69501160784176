import userServices from '../../../../services/user'
import erResponseService from '../../../../services/provider/erresponse'
export default {
    components: {
    },
    data() {
        return {
            item: [],
            admin: [],
            formType: 'INSERT',
            status: '',
        }
    },
    mounted() {
    },
    computed: {
        GetUserRole()
        {
            return this.$store.state.roleName
        },
        form() {
            if (this.formType == 'INSERT') {
                return {
                    title: 'Would you like to send recall response to supplier?',
                    showAdminCreation: true,
                    showStatusSwitcher: false
                }
            }
            if (this.formType == 'UPDATE') {
                return {
                    title: 'Update Supplier',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
        },
    },
    methods: {
        sendEmail(e) {
            let formData = new FormData()
            formData.append('supplierEmail', this.item.response_email == null ? this.item.supplier_email : this.item.response_email)
            formData.append('supplierName', this.item.suppliername == undefined ? this.item.supplier_name :this.item.suppliername)
            formData.append('recallId', this.item.recallid)
            formData.append('recallName', this.item.name == undefined ? this.item.supplier_name : this.item.name)
            var RecallId = this.item.responder_recallid == undefined ? this.item.id : this.item.responder_recallid
            erResponseService.sendResponderMail(formData,RecallId).then(resp=>{
                if(!resp.error){
                    this.$refs.modal.hide()
                    this.$parent.$options.parent.fetchDataOnSuccess('Response sent successfully',null)
                }
            })
        },
        beforeupdateRecall() {
            var itemData = {
                'Files': this.item.Files,
                'RecallId': this.item.recallid != undefined ? this.item.recallid : this.item.RecallId ,
                'supplierEmail': this.item.supplierEmail,
                'supplierName': this.item.suppliername!= undefined ? this.item.suppliername : this.item.supplierName,
                'supplierRecallId': this.item.userRecallId,
                'supplierRecallName': this.item.name != undefined ? this.item.name : this.item.supplierRecallName,
                'userId': this.item.userId,
                'userRecallId': this.item.supplierRecallId,
                'responceStatus': 'SENT',
                'createdAt': '10/10/2020',
                'disposalMethod': this.item.disposalMethodValue,
                'affectedProduct': this.item.affectedProduct,
                'notificationDate': this.item.notificationDate,
                'messageeId': ''
            }
            userServices.updateRecall(itemData).then(resp => {
                console.clear()
            })
        },
        updateRecall(msgID) {
            var itemData = {
                'Files': this.item.Files,
                'RecallId': this.item.recallid != undefined ? this.item.recallid : this.item.RecallId ,
                'supplierEmail': this.item.supplierEmail,
                'supplierName': this.item.suppliername!= undefined ? this.item.suppliername : this.item.supplierName,
                'supplierRecallId': this.item.userRecallId,
                'supplierRecallName': this.item.name != undefined ? this.item.name : this.item.supplierRecallName,
                'userId': this.item.userId,
                'userRecallId': this.item.supplierRecallId,
                'responceStatus': 'Update',
                'createdAt': '10/10/2020',
                'disposalMethod': this.item.disposalMethodValue,
                'affectedProduct': this.item.affectedProduct,
                'notificationDate': this.item.notificationDate,
                'messageeId': msgID
            }
            userServices.updateRecall(itemData).then(resp => {
                this.$refs.modal.hide()
                this.$parent.$options.parent.fetchData('Response sent successfully')
            })
        },
        forceRerenderInsert(val) {
            this.status = val.status == undefined ? val.response_status : val.status
            this.status = this.status == 'Bounce' ? 'ERROR' : this.status
            this.item = val

            if(this.$store.state.user != '')
            {
                var userData = this.$store.state.user
                this.admin.name = userData.fname + ' ' + userData.lname
                this.admin.email = userData.email
                this.admin.title = ''
                this.admin.companyname = userData.org_name
                this.admin.phoneno = userData.phone??''
            }
            else
            {
                this.admin.name = ''
                this.admin.email = ''
                this.admin.title = ''
                this.admin.companyname = ''
                this.admin.phoneno = ''
            }
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        cancel() {
            this.$refs.modal.hide()
        }
    }
}