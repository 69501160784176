
import erResponseService from '../../../services/provider/erresponse'
import authService from '../../../services/auth'
import _ from 'lodash'
import { RECALL_STATUS, RECALL_STATUS_PROVIDER, RECALL_CLASSIFICATIONS,ER_RECALL_STATUS ,AFFECTED_PRODUCT_STATUS,OUTPUT_DATE_FORMAT} from '../../../misc/commons'
import SupplierModal from './SupplierModal'
import ResponseModal from './ResponseModal'
export default {
    components: {
        SupplierModal,
        ResponseModal
    },
    data() {
        return {
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            delay: 700,
            clicks: 0,
            timer: null,
            total: 0,
            items: [],
            supplierModalKey: 0,
            respModalKey: 0,
            listSupplier: [],
            listSupplierEmail: [],
            listUserName: [],
            listProviderName: [],
            roleName: '',
            OrgType: '',
            count: 0,
            organizationId: null,
            userId: 0,
            search: {
                responseStatus: null,
                affectedProduct: null,
                respondent: null,
                supplierOrgId: null,
                providerOrgId: null,
                supplierOrgName: null,
                status: null,
                providername: null
            },
            user: {
                email: '',
                fname: '',
                lname: '',
                full_name: '',
                title: '',
                location: '',
                phone: '',
                photo: '',
                status: '',
                org_name: '',
                role: ''
            },
            searchQuery: {},
            header: [
                {
                    label: 'Status',
                    name: 'response_status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall'
                },
                {
                    label: 'Provider',
                    name: 'provider_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-15percent',
                    row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Supplier',
                    name: 'supplier_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-15percent',
                    row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Recall ID',
                    name: 'recallid',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-15percent',
                    row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Name',
                    name: 'recallname',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall'
                },
                {
                    label: 'Documents',
                    name: 'extension',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-15percent',
                    row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Site',
                    name: 'recall_sites',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Notification Date',
                    name: 'notification_date',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall'
                },
                {
                    label: 'Response Date',
                    name: 'response_date',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall'
                },
                {
                    label: 'Respondent',
                    name: 'username',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    visibility: this._checkRole('Reponder'),
                    column_classes: 'customalign-15percent',
                    row_classes: 'customalign-15percent wordbreakall'

                },
                {
                    label: 'Affected Product',
                    name: 'disposal_method',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall'
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                }
            ],

            listCategories: [],
            recall_status: _.cloneDeep(RECALL_STATUS),
            er_recall_status: _.cloneDeep(ER_RECALL_STATUS),
            recall_status_provider: RECALL_STATUS_PROVIDER,
            recall_classification: RECALL_CLASSIFICATIONS,
            affected_product_status: AFFECTED_PRODUCT_STATUS,
        }
    },
    created(){
        this.roleName=this.$store.state.roleName
        this.fetchProfile()
    },
    mounted() {
        this.findSupplier()
        this.findUserNameByOrgId()
        this.findProviderNameByOrgId()
        this.fetchData()
        this.GetUserId
    },
    computed: {
        GetUserId()
        {
            return this.$store.state.user.id
        },
    },
    methods: {
        clicked(){
            console.log('clicked')
        },
        doubleClick(event){
            this.clicks++
            if(this.clicks === 1) {
                var self = this
                this.timer = setTimeout(function() {
                    self.clicks = 0
                }, this.delay)
            } else{
                clearTimeout(this.timer)
                this.viewResponseRecall(event)
                this.clicks = 0
            }
        },
        fetchProfile() {
            authService.getUserProfile().then(res => {
                if (!res.error) {
                    this.user = res.data.d
                }
            })
        },
        onCompleted() {
            this.findSupplier()
            this.findUserNameByOrgId()
            this.findProviderNameByOrgId()
            this.fetchData()

        },
        supplierChange() {
            this.fetchData()
        },
        findSupplier() {
            erResponseService.findMeta().then(resp => {
                this.listSupplier = [
                    ...resp.data.d
                ]
            })

        },
        findProviderNameByOrgId() {
            erResponseService.findProviderName().then(resp => {
                this.listProviderName = [
                    ...resp.data.d
                ]
            })

        },
        findUserNameByOrgId() {
            erResponseService.findUserName(this.$store.state.orgId).then(resp => {
                this.listUserName = [
                    ...resp.data.d
                ]
            })

        },

        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        addMoreClick() {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderInsert(this.items)
            })
        },
        fetchData() {
            this.search.supplierOrgId=null
            this.listSupplier.forEach((value, index) => {
                if (this.search.supplierOrgName == value.supplier_name)
                    this.search.supplierOrgId = value.organizationId
            })

            this.search.providerOrgId=null
            this.listProviderName.forEach((value, index) => {
                if (this.search.providername == value.name)
                    this.search.providerOrgId = value.organizationId
            })

            this.listUserName.forEach((value, index) => {
                if (this.search.respondent == value.username)
                    this.search.userId = value.userId
            })
            if(this.searchQuery.sort != undefined)
            {
                erResponseService.findNotiErResponse({
                    ...this.searchQuery,
                    sortField: this.searchQuery.sort != undefined ? (this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null) : null,
                    sortDirection: this.searchQuery.sort != undefined ? (this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null) : null,
                    ...this.search,
                }).then(resp => {
                    if (!resp.error) {
                        var userData = this.GetUserId
                        this.items = resp.data.d.c
                        this.total = resp.data.d.t
                    }

                })
            }
        },
        fetchDataOnSuccess(val,mode,item) {
            this.fetchData()
            this.findSupplier()
            this.findUserNameByOrgId()
            this.findProviderNameByOrgId()
            if (val != null && val != undefined && val != '') {
                this._showToast(val, { variant: 'success' })
            }
            if (mode != null && mode != undefined && mode != '') {
                this.RespClick(item)
            }
        },
        addMoreRespClick(val,mode) {
            if(val.files.length > 0 && val.supplier_email != '' && val.supplier_email != null ){
                if (mode == 'btn'){
                    this.respModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.respModal.forceRerenderInsert(val)
                    })
                }
                else if(mode == 'opt')
                {
                    if(val.status == 'ERROR')
                    {
                        this.supplierModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                        })
                    }
                    else if(val.status == undefined)
                    {
                        var isFile = true
                        val.files.forEach((x, i) => {
                            if(x.extension == null)
                            {
                                isFile = false
                            }
                            else
                            {
                                this.respModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.respModal.forceRerenderInsert(val)
                                })
                            }
                            if(!isFile){
                                this.supplierModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                                })
                            }
                        })
                    }
                    else
                    {
                        this.respModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.respModal.forceRerenderInsert(val)
                        })
                    }
                }
            }
            else
            {
                if(val.status == 'SENT')
                {
                    this.respModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.respModal.forceRerenderInsert(val)
                    })
                }
                else if (mode == 'btn'){
                    if(val.status == 'ERROR')
                    {
                        this.respModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.respModal.forceRerenderInsert(val)
                        })
                    }
                    else if(val.status == 'DRAFT')
                    {
                        this.supplierModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                        })
                    }
                    else
                    {
                        this.respModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.respModal.forceRerenderInsert(val)
                        })
                    }
                }
                else
                {
                    this.supplierModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                    })
                }
            }
        },
        RespClick(val) {
            if(val.FileLen > 0 && val.response_email != '' && val.response_email != null){
                this.respModalKey += 1
                this.$nextTick(() => {
                    this.$refs.respModal.forceRerenderInsert(val)
                })
            }
        },
        viewRecall(item) {
            this.$router.push({ name: 'noti_er_Responses', params: { id: item.communicationId } })
        },
        editRecall(item, e) {
            this.$router.push({ name: 'prov_Recall_EditItem', params: { id: item.communicationId } })
            e.stopPropagation()
        },
        viewResponseRecall(val) {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderUpdate(val, [],'VIEW')
            })
        },
        _checkRole(roleName) {
            if(this.$store.state.roleName==='Responder')
            {  return false}else{return true}

        },
        async download(dataurl, filename,contentType) {
            this.openExternalLink(dataurl)
        },
        getRecallByUId(communicationId) {
            this.EditClick(communicationId)
        },
        EditClick(val) {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'UPDATE')
            })
        },
        deleteByGUID(val) {
            this._showConfirm('Are you sure you want to delete the recall, this cannot be undone?', { title: 'Delete Recall', variant: 'danger' }).then(confirm => {
                if (confirm) {
                    erResponseService.deleteResponderRecall(val.responder_recallid).then(resp => {
                        this.fetchDataOnSuccess('Recall deleted successfully',null)
                    })
                }
            })
        },
        findRECSVReport() {
            erResponseService.downloadCSVResponderRecall().then(resp => {
            })
        },
        findREXLSXReport() {
            erResponseService.downloadXLSXResponderRecall().then(resp => {
            })
        },
    }
}