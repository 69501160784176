import _ from 'lodash'
import VueDropzone from 'vue2-dropzone'
import ResponseModal from '../ResponseModal'
import erResponseService from '../../../../services/provider/erresponse'
import moment from 'moment-timezone'
export default {
    components: {
        VueDropzone,
        ResponseModal
    },
    props: ['infoData', 'recallMetaData', 'recallDisabled'],
    data() {
        return {
            item: {
                suppliername: null,
                name: null,
                recallid: null,
                id: null,
                response_email: null,
                documents: null,
                createdBy: null,
                notificationDate: null,
                supplierEmail: null,
                affectedProduct: false,
                disposalMethod: '',
                disposalMethodValue: '',
                disposalMethodView: '',
                response_date: '',
                FileLen: 0,
            },
            formType: 'INSERT',
            recallId: null,
            errorList: [],
            RecallsList: [],
            sup: 'sup',
            sup1: 'sup1',
            filesUpload: [],
            filespaths: [],
            s3upload: [],
            IsUpdated: false,
            filesUploadUpdate: [],
            filesUploadOriginal: [],
            respModalKey: 0,
            cnt: 0,
            uploadcnt: 0,
            thumbnailIndex: -1,
            updateRecallId: '',
            disabledDates: {
                from: new Date()
            }

        }
    },
    mounted() {
        this.info = _.cloneDeep(this.infoData)
    },
    computed: {
        form() {
            if (this.formType == 'INSERT') {
                return {
                    title: 'Add Recall Response',
                    showAdminCreation: true,
                    showStatusSwitcher: false,
                    class: 'card-title'
                }
            }
            if (this.formType == 'UPDATE') {
                return {
                    title: 'Update Recall Response',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
            if (this.formType == 'RES_UPDATE') {
                return {
                    title: 'Recall Response',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
            if (this.formType == 'VIEW') {
                return {
                    title: 'Recall Response',
                    showAdminCreation: true,
                    showStatusSwitcher: false,
                    class: 'card-title'
                }
            }
        },
    },
    methods: {
        removeDocument(index) {
            this.filesUpload.splice(index, 1)
            this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
            if (this.formType == 'RES_UPDATE') {
                if (this.item.response_email != '' && this.filesUpload.length > 0) {
                    if (this.item.status != 'Bounce') {
                        this.$refs.modal.$refs.body.children[0].hidden = true
                    }
                    this.$refs.modal.$refs.footer.children[1].hidden = true
                    this.$refs.modal.$refs.footer.children[2].hidden = false
                }
                else {
                    this.$refs.modal.$refs.body.children[0].hidden = true
                    this.$refs.modal.$refs.footer.children[1].hidden = false
                    this.$refs.modal.$refs.footer.children[2].hidden = true
                }
            }
        },
        async handleFilesUpload() {
            let uploadedFiles = this.$refs.files.files
            for (let i = 0; i < uploadedFiles.length; i++) {
                if (uploadedFiles[i].size == 0) {
                    this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                }
                else {
                    if (this.formType == 'UPDATE' || this.formType == 'RES_UPDATE') {
                        this.filesUpload.push({
                            name: uploadedFiles[i].name,
                            type: uploadedFiles[i].type,
                            size: uploadedFiles[i].size,
                            file: uploadedFiles[i],
                        })
                    }
                    else {
                        this.filesUpload.push({
                            file: uploadedFiles[i],
                            note: '',
                            is_thumbnail: false
                        })
                    }
                }
            }
            if (this.formType == 'RES_UPDATE') {
                const isValid = await this.$refs['observer'].validate()
                if (isValid) {

                    if (this.item.status == 'Bounce') {
                        this.$refs.modal.$refs.footer.children[1].hidden = true
                        this.$refs.modal.$refs.footer.children[2].hidden = false
                    }
                    else {
                        this.$refs.modal.$refs.body.children[0].hidden = true
                        this.$refs.modal.$refs.footer.children[1].hidden = true
                        this.$refs.modal.$refs.footer.children[2].hidden = false
                    }

                }

            }
            this.$refs.files.value = ''
        },
        forceRerenderInsert(list) {
            this.formType = 'INSERT'
            list.forEach((x, i) => {
                this.RecallsList.push(x.recallid.trim())
            })
            this.$refs.modal.show()
        },
        forceRerenderView(list) {
            this.formType = 'VIEW'
            list.forEach((x, i) => {
                this.RecallsList.push(x.recallid.trim())
            })
            this.$refs.modal.show()
        },
        forceRerenderUpdate(val, list, Mode) {
            this.formType = Mode
            list.forEach((x, i) => {
                this.RecallsList.push(x.recallid.trim())
            })
            this.updateRecallId = val.recallid.trim()
            this.item = {
                suppliername: val.supplier_name,
                name: val.recallname,
                recallid: val.recallid,
                id: val.responder_recallid,
                status: val.response_status == 'ERROR' ? 'Bounce' : val.response_status,
                response_email: val.supplier_email,
                notificationDate: val.notification_date,
                response_date: val.response_date,
                disposalMethodView: val.disposal_method == undefined || val.disposal_method == null ? '' : val.disposal_method,
                disposalMethod: val.disposal_method == 'Destroyed' ? true : (val.disposal_method == 'Returned' ? false : ''),
                affectedProduct: val.disposal_method == '' ? null : val.affected_product,
                documents: null,
                createdBy: null,
                supplierEmail: val.supplier_email,
                Files: []
            }
            this.filesUploadOriginal = []
            this.filesUploadOriginal = val.files
            val.files.forEach((x, i) => {
                if (x.extension != null) {
                    this.filesUpload.push({
                        name: x.filename + x.extension,
                        extension: x.extension,
                        type: x.mimetype,
                        size: x.fileSize,
                        path: x.signed_url,
                        signed_url_review: x.signed_url_review,
                        pathS: x.path,
                        responder_document: x.responder_document,
                        responder_recallid: x.responder_recallid,
                    })
                }

            })
            this.$refs.modal.show()
        },
        async createRecall(e) {
            const isValid = await this.$refs['observer'].validate()
            if (isValid) {
                if (!this.RecallsList.includes(this.item.recallid.trim())) {
                    let formData = new FormData()
                    formData.append('supplierEmail', this.item.response_email == null ? '' : this.item.response_email)
                    formData.append('supplierName', this.item.suppliername)
                    formData.append('recallId', this.item.recallid)
                    formData.append('recallName', this.item.name)
                    formData.append('notificationDate', this.item.notificationDate ? moment(this.item.notificationDate).format('YYYY/MM/DD') : '')
                    formData.append('responseStatus', 'DRAFT')
                    formData.append('disposalMethod', this.item.disposalMethodValue ?? '')
                    formData.append('affectedProduct', this.item.affectedProduct)
                    this.filesUpload.forEach((x, i) => {
                        if (!x.id) {
                            formData.append('document', x.file)
                        }
                    })

                    erResponseService.saveResponderRecall(formData).then(resp => {
                        if (!resp.error) {
                            this.$refs.modal.hide()
                            this.item.FileLen = this.filesUpload.length
                            this.item.status = 'DRAFT'
                            this.item.responder_recallid = resp.data.d
                            this.$parent.$options.parent.fetchDataOnSuccess('Recall created successfully', this.item.recallid, this.item)
                        }
                    })
                }
                else {
                    this._showToast('Recall Id is already existed.', { variant: 'danger', title: 'Error' })
                }
            }
        },
        async updateRecall(e, Mode) {
            var isValid = await this.$refs['observer'].validate()
            if (isValid) {
                if (!this.RecallsList.includes(this.item.recallid.trim()) || this.updateRecallId.trim() == this.item.recallid.trim()) {
                    let formData = new FormData()
                    formData.append('supplierEmail', this.item.response_email == null ? '' : this.item.response_email)
                    formData.append('supplierName', this.item.suppliername)
                    formData.append('recallId', this.item.recallid)
                    formData.append('recallName', this.item.name)
                    formData.append('notificationDate', this.item.notificationDate ? moment(this.item.notificationDate).format('YYYY/MM/DD') : '')
                    formData.append('responseStatus', 'DRAFT')
                    formData.append('disposalMethod', (this.item.disposalMethodValue == null || this.item.disposalMethodValue == undefined) ? '' : this.item.disposalMethodValue)
                    formData.append('affectedProduct', this.item.affectedProduct == true ? true : false)
                    this.filesUpload.forEach((x, i) => {
                        if (x.file != undefined) {
                            formData.append('document', x.file)
                        }
                        else {
                            formData.append('actualfiles[' + i + '].responderrecallId', x.responder_recallid)
                            formData.append('actualfiles[' + i + '].filename', x.name)
                            formData.append('actualfiles[' + i + '].responderdocumentId', x.responder_document)
                            formData.append('actualfiles[' + i + '].path', x.pathS)
                            formData.append('actualfiles[' + i + '].extension', x.extension)
                            formData.append('actualfiles[' + i + '].mimetype', x.type)
                            formData.append('actualfiles[' + i + '].signedurl', x.signed_url)
                        }
                    })
                    this.filesUploadOriginal.forEach((x, i) => {
                        formData.append('files[' + i + '].responderrecallId', x.responder_recallid)
                        formData.append('files[' + i + '].filename', x.filename)
                        formData.append('files[' + i + '].responderdocumentId', x.responder_document)
                        formData.append('files[' + i + '].path', x.path)
                        formData.append('files[' + i + '].extension', x.extension)
                        formData.append('files[' + i + '].mimetype', x.mimetype)
                        formData.append('files[' + i + '].signedurl', x.signed_url)
                    })
                    erResponseService.updateResponderRecall(formData, this.item.id, 'UPDATE').then(resp => {
                        if (!resp.error) {
                            this.$refs.modal.hide()
                            this.item.FileLen = this.filesUpload.length
                            this.$parent.$options.parent.fetchDataOnSuccess('Recall updated successfully', this.item.recallid, this.item, Mode)
                        }
                    })
                }
                else {
                    this._showToast('Recall Id is already existed.', { variant: 'danger', title: 'Error' })
                }
            }
        },
        isChange(eve) {
            this.item.disposalMethod = ''
            this.item.disposalMethodValue = ''
            if (eve.target.checked) {
                this.item.affectedProduct = true
            }
            else {
                this.item.affectedProduct = false
            }
        },
        isDisposal(eve) {
            if (eve) {
                this.item.disposalMethodValue = 'Destroyed'
            }
            else {
                this.item.disposalMethodValue = 'Returned'
            }
        },
        async download(dataurl, filename, contentType) {
            this.openExternalLink(dataurl)
        },
    }
}